<template>
  <div class="comment-input-component">
    <a-input v-model="comment" :placeholder="$t('comments.placeholder')"/>
    
    <Icon :size="20" type="smile"/>
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'CommentInput',
  components: {
    Icon
  },
  data () {
    return {
      comment: null
    }
  }
}
</script>
