<template>
  <div class="comment-component">
    <div class="user">
      <a-avatar :size="24" :src="avatar">
        <a-icon slot="icon" type="user"/>
      </a-avatar>

      <div class="name">
        <span> {{ name }} </span>
      </div>

      <span class="date"> {{ date }} </span>
    </div>

    <p class="comment"> {{ comment }} </p>
  </div>
</template>

<script>

export default {
  name: 'Comment',
  computed: {
    avatar () {
      return null
    },
    name () {
      return 'Darlene Robertson'
    },
    premium () {
      return true
    },
    date () {
      return '12:36'
    },
    comment () {
      return 'Haha that\'s terrifying'
    }
  }
}
</script>
