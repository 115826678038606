<template>
  <div class='disqualified'>
    <div class='disqualified__user w-100'>
      <div class='disqualified__avatar'>
        <a-avatar :size="24" :src="avatar">
          <a-icon slot="icon" type="user" />
        </a-avatar>
      </div>
      <div class='d-flex justify-content-between flex-grow-1'>
        <div class='disqualified__name'>{{ name }}</div>
        <span class='disqualified__label'>was disqualified</span>
      </div>
    </div>
    <p v-if='text' class='disqualified__text'>
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Disqualified',
  props: {
    competitor: {
      type: Object,
      required: true
    },
    competition: {
      type: Object,
      required: true
    }
  },
  computed: {
    name() {
      return this.competitor.name
    },
    avatar() {
      return this.competitor.photo
    },
    text() {
      return this.competition.markers.find(marker => {
        return marker.user_id === this.competitor.id
      }).notes
    }
  }
}
</script>

<style scoped>
.disqualified {
  background: #1D2130;
  border-radius: 12px;
  padding: 15px 0;
}

.disqualified__user {
  display: inline-flex;
  align-items: center;
}

.disqualified__name {
  font-size: 14px;
  line-height: 120%;
  color: #D8D8D8;
  margin-left: 10px;
}

.disqualified__label {
  color: #ff3737;
  font-size: 10px;
  align-self: flex-end;
  line-height: 1;
}

.disqualified__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #7B8B97;
  margin: 0;
  margin-top: 10px;
}</style>
