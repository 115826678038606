<template>
  <div class="competitor-component" :class="classes">
    <div class="track" v-if="competition.kind_sport.name == 'Swimming'"> {{ '#' + track }} </div>

    <router-link class="avatar" :to="{ name: 'account', params: { userID: id } }">
      <a-avatar :size="24" :src="avatar">
        <a-icon slot="icon" type="user" />
      </a-avatar>

      <!--      <div class="disqualification" v-if="showDisqualification">-->
      <!--        <Icon :size="6" type="cross"/>-->
      <!--      </div>-->
    </router-link>

    <div class="user">
      <span class="name"> {{ name }} </span>

      <div class="country" v-if="country">
        <img :src="country.icon" />

        <span> {{ country.code }} </span>
      </div>
    </div>
    <span class="disqualification" v-if="showDisqualification"> {{ $t('pages.verdict.dsq') }} </span>
    <p v-if="note && disqualifiedNote" class='competitor-component__comment'>{{ disqualifiedNote }}</p>
    <div class="result" v-if="!showDisqualification">
      <div class="place" :class="getMarker(id).place < 5 ? `place_${getMarker(id).place}` : 'place__other'">{{
        getMarker(id).place }}
      </div>
      <div>{{ getMarker(id).race_time }}</div>

    </div>
  </div>
</template>

<script>
// import Icon from '@/components/Icon'
import competitor from '@/mixins/competitor'

export default {
  name: 'Competitor',
  mixins: [
    competitor
  ],
  components: {
    // Icon
  },
  props: {
    competitor: {
      type: Object,
      required: true
    },
    competition: {
      type: Object,
      required: true
    },
    note: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  computed: {
    classes() {
      const { disqualified } = this

      return { disqualified }
    },
    showTrack() {
      return !this.disqualified
    },
    showDisqualification() {
      return this.disqualified
    },
  },
  methods: {
    getMarker(id) {
      let marker = this.competition.markers.find((marker) => {
        return id === marker.user_id
      })
      return marker
    }
  }
}
</script>

<style scoped lang='stylus'>
.competitor-component__comment {
  color: #fff;
  width: 100%;
}
.disqualification{
  margin-left: auto;
  align-self: flex-end;
  font-size: 10px;
  color: #D21341;
  line-height: 1;
}
.result {
  align-self: flex-end;
  margin-left: auto;
}

.place {
  color: black
}

.place_1{ 
  background-color: gold;
}

.place_2 {
  background-color: silver;
}

.place_3 {
  background-color: #cd7f32;
}

.place_4 {
  background-color: grey;
}

.place__other{
  color: white !important
}
</style>
