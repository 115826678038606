<template>
  <view-container name="verdict">
    <view-header :fixed="true">
      <div class="verdict-header">
        <div class="head">
          <router-link-back class="back">
            <Icon :size="24" type="arrow-left" />
          </router-link-back>

          <Organizer v-if="organizer" :competitor="organizer" />
        </div>
      </div>
    </view-header>

    <view-content>
      <div class='verdict-header__content'>
        <div class="video">
          <video :src="video" controls></video>
        </div>

        <div class="competitors">
          <Competitor v-for="competitor in notDisqualified" :key="competitor.id" :competitor="competitor"
            :competition="competition" />
          <Competitor v-for="competitor in disqualified" :key="competitor.id" :competitor="competitor"
            :competition="competition" />
          <div class="expand expand__row" v-if="showExpand && disqualified.length && hasComments" @click="toggleExpand">
            <h2 class='disqualified-title'>Official notes</h2>
            <Icon :size="14" :type="expnadIcon" />
          </div>
          <template v-if='disqualified.length && expand'>
            <disqualified v-for="competitor in disqualified" :key="competitor.id" :competitor="competitor"
              :competition="competition" />
          </template>
        </div>


      </div>
    </view-content>

    <view-content v-if="false">

      <div class="title">
        <h4> {{ $t('pages.verdict.comments') }} </h4>

        <div class="views">
          <Icon :size="17" type="eye" />

          <span> {{ views }} </span>
        </div>

        <div class="comments">
          <Icon :size="17" type="discuss" />

          <span> {{ comments }} </span>
        </div>
      </div>

      <a-divider></a-divider>

      <Comment />
    </view-content>

    <view-footer v-if="false">
      <CommentInput />
    </view-footer>
  </view-container>
</template>

<script>
import Icon from '@/components/Icon'
import Comment from '@/components/Comments/Comment'
import Organizer from '@/components/Verdict/Organizer'
import Competitor from '@/components/Verdict/Competitor'
import competition from '@/mixins/competition'
import CommentInput from '@/components/Comments/CommentInput'
import { getVerdict } from '@/api/public/competition'
import Disqualified from '@/components/Disqualified/Disqualified'

export default {
  name: 'Verdict',
  mixins: [
    competition
  ],
  components: {
    Icon,
    Comment,
    Organizer,
    Competitor,
    CommentInput,
    Disqualified
  },
  props: {
    competitionId: {
      type: [String, Number],
      required: true
    },
  },
  mounted() {
    this.initCompetition()
  },
  data() {
    return {
      expand: false,
      competition: null
    }
  },
  computed: {
    comments() {
      return '568'
    },
    views() {
      return '25.6k'
    },
    expnadIcon() {
      return this.expand ? 'caret-top' : 'caret-down'
    },
    showExpand() {
      return this.competitors?.length > 1
    },
    notDisqualified() {
      return this.sortedCompetitors.filter(item => {
        return this.competition.markers.find(marker => {
          return marker.user_id === item.id
        })?.is_disqualification === false
      })
    },
    disqualified() {
      return this.sortedCompetitors.filter(item => {
        return this.competition.markers.find(marker => {
          return marker.user_id === item.id
        })?.is_disqualification === true
      })
    },
    sortedCompetitors() {
      const getTrack = (competitor) => {
        const challenge = this.competition?.challenges?.find(({ initiator, opponent }) => {
          return initiator.id === competitor.id || opponent.id === competitor.id
        })

        return challenge?.initiator.id === competitor.id ? 4 : challenge?.track || Infinity
      }

      const competitors = [...this.competitors].sort((a, b) => {
        return getTrack(a) - getTrack(b) || 0
      })

      return competitors
    },
    hasComments() {
      for (let disc of this.disqualified) {
        if (this.competition.markers.find(el => el.user_id === disc.id && el.notes.length > 0)) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    async initCompetition() {
      try {
        const { data: competition } = await getVerdict(this.competitionId)
        this.competition = competition
      } catch (e) {
        console.log(e)
      }
    },
    toggleExpand() {
      this.expand = !this.expand
    }
  }
}
</script>

<style scoped lang='stylus'>
.disqualified-title {
  color: #D8D8D8;
  font-size: 1.1rem;
}

.expand__row {
  padding 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.competitors_component{ 
  padding: 0 0 12px;
}
</style>
