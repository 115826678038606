<template>
  <div class="organizer-component">
    <router-link class="avatar" :to="{ name: 'account', params: { userID: id } }">
      <a-avatar :size="32" :src="avatar">
        <a-icon slot="icon" type="user"/>
      </a-avatar>
    </router-link>

    <div class="user">
      <span class="organizer"> {{ $t('pages.verdict.organizer') }} </span>
      <div class="name">
        <span> {{ name }} </span>

        <div class="country" v-if="country">
          <img :src="country.icon"/>

          <span> {{ country.code }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import competitor from '@/mixins/competitor'

export default {
  name: 'Organizer',
  mixins: [
    competitor
  ],
  props: {
    competitor: {
      type: Object,
      required: true
    }
  }
}
</script>
