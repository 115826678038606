import moment from 'moment-timezone'
import { mapState } from 'vuex'
import { elapsedTime } from '@/helpers'

export default {
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user
    }),
    id() {
      return this.competitor?.id || null
    },
    me() {
      return this.user?.id === this.id
    },
    name() {
      let name = this.competitor?.name || this.competitor?.username || null

      if (this.me) {
        name = this.$t('competitor.you')
      }

      return name
    },
    avatar() {
      return this.competitor?.photo || null
    },
    status() {
      if (this.organizer) {
        return 1
      }

      const challenge = this.challenge

      if (challenge?.accepted_at) {
        return 1
      }

      if (challenge?.rejected_at) {
        return 2
      }

      const date = this.competition?.event_at

      if (date && moment().isAfter(moment(date).subtract(4, 'd'))) {
        return 2
      }

      return 0
    },
    statusName() {
      const statuses = ['awaiting', 'accepted', 'rejected']

      return statuses[this.status]
    },
    country() {
      const country = this.competitor?.country

      if (!country?.icon || !country?.code) {
        return null
      }

      return country
    },
    premium() {
      return true
    },
    raiting() {
      return this.competitor?.rating || 0
    },
    position() {
      return this.competitor?.position || 0
    },
    organizer() {
      return (this.competition.initiator?.id || this.competition.challenges?.[0]?.initiator?.id) === this.id
    },
    // organizer () {
    //   return this.challenges?.[0].initiator?.id === this.id
    // },
    // eventOrganizer() {  
    //   return this.competition.initiator?.id === this.id
    // },
    challenge() {
      return this.challenges.find(({ opponent, initiator }) => opponent && opponent.id === this.id || initiator && initiator.id === this.id)
    },
    challenges() {
      return this.competition?.challenges || []
    },
    marker() {
      return this.competition?.markers?.find(({ user_id }) => user_id === this.id)
    },
    winner() {
      return this.place === 1
    },
    place() {
      return this.marker?.place || null
    },
    disqualified() {
      return this.marker?.is_disqualification || false
    },
    disqualifiedNote() {
      return this.marker.notes
    },
    track() {
      return this.organizer ? 4 : this.challenge?.track
    },
    time() {
      return this.marker?.race_time || null
    },
    completed() {
      return this.competition?.status === 'video-is-live'
    },
    parameters() {
      const birthday = this.competitor?.birthday

      return {
        age: birthday ? elapsedTime(moment(), birthday, 'y') : 0,
        height: this.competitor?.height || 0,
        weight: this.competitor?.weight || 0,
        ratio: '62%'
      }
    }
  }
}
